import React from "react";
import PageLayout from "../components/layout/PageLayout";
import Masthead from "../components/layout/Masthead";
import Seo from "../components/layout/SEO";
import ProjectsContainer from "../components/projects/ProjectsContainer";

import workAnim from "../assets/json/work.json";

const work = () => {
  return (
    <PageLayout includeCTA anim={workAnim}>
      <Seo title="Work - Simplicitic Innovations Sdn Bhd" />
      <Masthead
        title={
          <>
            Some of our <span>past projects</span>
          </>
        }
        tag="Our work"
      />
      <ProjectsContainer />
    </PageLayout>
  );
};

export default work;
